import React from 'react';
import { Form, Field } from 'react-final-form'
import { Input, Textarea } from 'components/formHelper';
import axios from 'axios';

const DemoForm = () => {
  const [error, setError] = React.useState(false);
  const [successed, setSuccessed] = React.useState(false);
  const [isFetching, setIsFetching] = React.useState(false);

  function onSubmit(values, form) {
    setIsFetching(true);
    const url = 'https://t99oynj1q2.execute-api.us-east-1.amazonaws.com/dev/api/send-email';
    axios({
      url,
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      data: {
        from: values.full_name,
        to: 'galam.kw@gmail.com',
        subject: `Galam Request Demo Form - ${values.school_name} <${values.email}>`,
        html: `
          <h4>Name: ${values.full_name}</h4>
          <h4>School name: ${values.school_name}</h4>
          <h4>Email: ${values.email}</h4>
          <h4>Phone Number: ${values.phone_number}</h4>
          <h4>Number Of Student: ${values.number_of_student}</h4>
        `
      },
    })
    .then((response) => {
      setError(false)
      setSuccessed(true)
      setIsFetching(false);
      form.reset();
    })
    .catch((error) => {
      setError(true)
      setSuccessed(false)
      setIsFetching(false);
    });
  }
  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
          className="form"
          style={{
            maxWidth: 620,
            margin: '0 auto',
          }}
        >
          <div className="messages" />
          <div className="controls">
            <div className="row"> 
              <div className="col-md-12">
                <div className="form-group">
                  <label>الاسم</label>
                  <Field name="full_name" component="input" className="form-control" type="text" required />
                  <div className="help-block with-errors color-orange" />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>البريد الإلكتروني</label>
                  <Field name="email" component="input" className="form-control" type="email" required />
                  <div className="help-block with-errors color-orange" />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>رقم الجوّال</label>
                  <Field name="phone_number" component="input" className="form-control" type="text" />
                  <div className="help-block with-errors" />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>اسم الحضانة</label>
                  <Field name="school_name" component="input" className="form-control" type="text" />
                  <div className="help-block with-errors" />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label>عدد الطلاب</label>
                  <Field name="number_of_student" component="select" className="form-control" placeholder="عدد الطلاب">
                    <option value="1-20">1-20 طالب</option>
                    <option value="20-60">20-60 طالب</option>
                    <option value="60-250">60-250 طالب</option>
                    <option value="250-up">أكتر من 250 طالب</option>
                  </Field>
                  <div className="help-block with-errors" />
                </div>
              </div>
              <div className="col-md-12">
              {error && 
                <div style={{ color: 'red' }} role="alert">
                  حدث خطاء في السيرفر
                </div>}
                {successed &&
                <div style={{ color: '#1eda65'}} role="alert">
                  تم إرسال رسالتك بنجاح !
                </div>}
                <button type="submit" className="main-btn btn-3 pointer" >إرسال</button>
              </div>
            </div>
          </div>
        </form>
      )}
    />
  );
}

export default DemoForm;
