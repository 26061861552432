import React from 'react'
import DemoForm from './DemoForm';

const GetStarted = () => (
  <section className="welcome-area-2 sec-padding p-relative o-hidden text-center" data-scroll-index={1}>
    <div className="container">
      <div className="row welcome-text sec-padding flex-center">
        <div className="col-md-8 z-index-1">
          <h1 className="mb-20px">أطلب نسختك التجريبية الآن</h1>
          {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> */}
          {/* <a className="main-btn btn-3 btn-green mt-30px mr-15px" href="#0">Get Started</a>
          <a className="main-btn btn-2 btn-green mt-10px mr-15px" href="#0">Learn More</a> */}
          <br />
          <span className="mt-25px d-inline-block">
            <span style={{ direction: 'ltr' }} className="mr-20px d-inline-block mb-10px"><i className="fa fa-envelope color-blue" /> <a href="#0">galam.kw@gmail.com</a> </span>
            <span style={{ direction: 'ltr' }} className="mr-20px d-inline-block mb-10px"><i className="fa fa-phone color-blue" /> +96560765772</span>
          </span>
          {/* <span>Follow Us:
            <a href="#0" className="color-blue color-blue-hvr mr-5px"><i className="fa fa-facebook" /> </a> 
            <a href="#0" className="color-blue color-blue-hvr mr-5px"><i className="fa fa-twitter" /> </a> 
            <a href="#0" className="color-blue color-blue-hvr mr-5px"><i className="fa fa-behance" /> </a> 
            <a href="#0" className="color-blue color-blue-hvr mr-5px"><i className="fa fa-rss" /> </a> 
          </span> */}
        </div>
      </div>
    </div>
    <div className="shape-3 bg-gray p-absolute">
    </div>
    <div className="shape-2 bg-gray p-absolute">
    </div>
    <div className="shape-4 bg-gray p-absolute wow fadeInUp" data-wow-delay="0.3s">
    </div>
    <div className="shape-5 bg-gray p-absolute wow fadeInUp" data-wow-delay="0.3s">
    </div>
    <div className="shape-6 bg-gray p-absolute">
    </div>

    <DemoForm />

  </section>
);

export default GetStarted;
