import React from 'react'
import Layout from 'components/layout';
import SEO from 'components/seo';
import GetStarted from 'templates/GetStarted/index';
import FooterArea from 'components/blocks/FooterArea/index';

const GetStartedPage = () => (
  <Layout>
    <SEO
      title="تسجيل الدخول"
      keywords={[`الدخول`, `تسجيل`, `قلم`, `برنامج قلم`, `الحضانة`]}
    />
    <GetStarted />
    <FooterArea />
  </Layout>
);

export default GetStartedPage;
